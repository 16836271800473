<template>
<div class="layout">
    <web-header></web-header>
    <router-view></router-view>
    <web-footer></web-footer>
</div>

</template>

<style lang="scss">

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

</style>

<script>
import { defineComponent } from "vue";
import header from "./components/layout/header";
import footer from "./components/layout/footer";


export default defineComponent({
    name: "App",
    components: {
        WebHeader:header,
        WebFooter:footer
    }
});
</script>
