import { createApp } from 'vue'
// import moment from "moment";
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/lib/theme-chalk/index.css';

import axios from "./utils/http.js";
import "./style/reset.css";

import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)

app.use(store);
app.use(router);

app.use(ElementPlus)

app.config.globalProperties.$axios = axios;
// app.config.globalProperties.$moment = moment;

app.mount('#app');

export default app;
