import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index/index.vue'),
        meta: {
            title:"首页"
        }
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/product/product.vue'),
        meta: {
            title:"产品服务"
        }
    },
    {
        path: '/case',
        name: 'case',
        component: () => import('../views/case/case.vue'),
        meta: {
            title:"客户案例"
        }
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/news/news.vue'),
        meta: {
            title:"新闻资讯"
        }
    },
    {
        path: '/news-detail',
        name: 'news-detail',
        component: () => import('../views/news/news-detail.vue'),
        meta: {
            title:"新闻资讯"
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/about/about.vue'),
        meta: {
            title:"关于我们"
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
